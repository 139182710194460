<template>
    <div>
      <h3>{{ title }}</h3>
      <table>
        <thead>
          <tr>
            <th>Pos</th>
            <th>Time</th>
            <th data-full="Pontos" data-short="Pts">Pontos</th>
            <th data-full="Jogos" data-short="J">Jogos</th>
            <th data-full="Vitórias" data-short="V">Vitórias</th>
            <th data-full="Empates" data-short="E">Empates</th>
            <th data-full="Derrotas" data-short="D">Derrotas</th>
            <th data-full="Gols Pró" data-short="GP">Gols Pró</th>
            <th data-full="Gols Contra" data-short="GC">Gols Contra</th>
            <th data-full="Saldo de Gols" data-short="SG">Saldo de Gols</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(team, index) in teams" :key="team.name"
              :class="{ 'first-place': index === 0, 'last-places': index >= teams.length - 2 }">
            <td>{{ index + 1 }}</td>
            <td class="left">
              <img :src="team.img" alt="Team logo" class="team-logo">
              <span :data-full="team.name" :data-short="team.abbreviation" @click="$emit('show-history', team)">{{ team.name }}</span>
            </td>
            <td>{{ team.points }}</td>
            <td>{{ team.wins + team.draws + team.losses }}</td>
            <td>{{ team.wins }}</td>
            <td>{{ team.draws }}</td>
            <td>{{ team.losses }}</td>
            <td>{{ team.goalsFor }}</td>
            <td>{{ team.goalsAgainst }}</td>
            <td>{{ team.goalDifference }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      teams: {
        type: Array,
        required: true
      }
    },
    mounted() {
      this.updateTableHeaders();
      window.addEventListener('resize', this.updateTableHeaders);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateTableHeaders);
    },
    methods: {
      updateTableHeaders() {
        const isMobile = window.innerWidth <= 600;
        const headers = document.querySelectorAll('th[data-full][data-short]');
        headers.forEach(header => {
          if (isMobile) {
            header.textContent = header.getAttribute('data-short');
          } else {
            header.textContent = header.getAttribute('data-full');
          }
        });
  
        const teamNames = document.querySelectorAll('span[data-full][data-short]');
        teamNames.forEach(name => {
          if (isMobile) {
            name.textContent = name.getAttribute('data-short');
          } else {
            name.textContent = name.getAttribute('data-full');
          }
        });
      }
    }
  };
  </script>
  
  <style scoped>
  h3 {
    color: #42b983;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  .first-place {
    background-color: #d4edda;
  }
  
  .last-places {
    background-color: #f8d7da;
  }
  
  .left {
    display: flex;
    justify-content: flex-start;
  }
  
  .team-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    vertical-align: middle;
  }
  
  @media (max-width: 600px) {
    table, th, td {
      font-size: 0.8em;
    }

    h3 {
        margin-top: 2px;
        margin-bottom: 2px;
    }
  
    .team-logo {
      width: 20px;
      height: 20px;
    }
  }
  </style>
  