import { v4 as uuidv4 } from 'uuid';

const names = [
    'John', 'Paul', 'George', 'Ringo', 'Lionel', 'Diego', 'Cristiano', 'Zinedine', 'Andrea', 'Francesco',
    'Roberto', 'Carlos', 'Luis', 'Miguel', 'Sergio', 'Rafael', 'Lucas', 'Gabriel', 'Mateo', 'Matias',
    'Pedro', 'João', 'José', 'Antônio', 'Francisco', 'Raimundo', 'Sebastião', 'Jorge', 'Gustavo', 'Ricardo',
    'Tiago', 'Bruno', 'Rodrigo', 'Daniel', 'Felipe', 'Marcos', 'Leonardo', 'Vinícius', 'Eduardo', 'Fernando',
    'Guilherme', 'Samuel', 'Henrique', 'Vitor', 'Marcelo', 'Igor', 'Caio', 'André', 'Alexandre', 'Hugo',
    'Renato', 'Márcio', 'Alex', 'Fábio', 'Paulo', 'Adriano', 'Rogério', 'Murilo', 'Joaquim', 'Cristiano',
    'Edson', 'Leandro', 'Luciano', 'Maurício', 'Otávio', 'Raul', 'Sandro', 'Valter', 'Yuri', 'Zeca'
  ];
  
  const surnames = [
    'Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia', 'Martinez', 'Davis', 'Lopez', 'Gonzalez',
    'Silva', 'Santos', 'Oliveira', 'Souza', 'Lima', 'Pereira', 'Ferreira', 'Almeida', 'Costa', 'Rodrigues',
    'Martins', 'Rocha', 'Ribeiro', 'Alves', 'Barbosa', 'Cardoso', 'Correia', 'Farias', 'Mendes', 'Nogueira',
    'Teixeira', 'Vieira', 'Monteiro', 'Carvalho', 'Azevedo', 'Fonseca', 'Moreira', 'Barros', 'Campos', 'Moraes'
  ];
  
  const positions = ['G', 'D', 'M', 'A']; // Goleiro, Defensor, Meio-campista, Atacante

  const nationalities = [
    'Brasileiro', 'Argentino', 'Alemão', 'Francês', 'Italiano', 'Espanhol', 'Português', 'Inglês', 'Holandês', 'Belga',
    'Uruguaio', 'Colombiano', 'Chileno', 'Peruano', 'Paraguaio', 'Mexicano', 'Americano', 'Canadense', 'Australiano', 'Japonês'
  ];
  

function getRandomElement(array) {
  return array[Math.floor(Math.random() * array.length)];
}

function generatePlayer(teamId) {
    const firstName = getRandomElement(names);
    const lastName = getRandomElement(surnames);
    const position = getRandomElement(positions);
    const nationality = getRandomElement(nationalities);
    const age = Math.floor(Math.random() * (35 - 18 + 1)) + 18; // Idade entre 18 e 35
    const strength = Math.floor(Math.random() * 100) + 1; // Força entre 1 e 100
    const speed = Math.floor(Math.random() * 100) + 1; // Velocidade entre 1 e 100
    const shooting = Math.floor(Math.random() * 100) + 1; // Chute entre 1 e 100
    const attack = Math.floor(Math.random() * 100) + 1; // Ataque entre 1 e 100
    const defense = Math.floor(Math.random() * 100) + 1; // Defesa entre 1 e 100
    const value = (strength + speed + shooting + attack + defense) * 10000; // Valor baseado nos atributos
    
    // Critério para ser jogador estrela
    const starPlayer = strength > 80 || speed > 80 || shooting > 80 || attack > 80 || defense > 80;
  
    return {
      id: uuidv4(),
      teamId,
      name: `${firstName} ${lastName}`,
      position,
      nationality,
      age,
      strength,
      speed,
      shooting,
      attack,
      defense,
      value,
      retired: false, // Indica se o jogador está aposentado
      starPlayer // Indica se é um jogador estrela
    };
  }
  

export function generatePlayers(teamId, numPlayers = 23) {
  const players = [];
  let foreignersCount = 0;

  for (let i = 0; i < numPlayers; i++) {
    let player;
    do {
      player = generatePlayer(teamId);
    } while (player.nationality !== 'Brasileiro' && foreignersCount >= 5);

    if (player.nationality !== 'Brasileiro') {
      foreignersCount++;
    }

    players.push(player);
  }
  return players;
}
