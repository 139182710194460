<template>
  <div>
    <h2>Simulador de Rodadas</h2>
    <div class="buttons">
      <button v-if="championshipFirst" @click="startChampionship" class="btn btn-primary">Iniciar Campeonato</button>
      <button v-else-if="!championshipEnded" @click="simulateNextRound" class="btn btn-primary">Simular Próxima Rodada</button>
      <button v-else @click="startNewChampionship" class="btn btn-primary">Iniciar Novo Campeonato</button>
      <button @click="resetCurrentTeams" class="btn btn-secondary">Zerar Pontos</button>
    </div>

    <GamesList title="Jogos da Série A" :matches="matchesA" @show-history="showHistory" @show-players="showPlayers" />
    <StandingsTable title="Tabela de Classificação - Série A" :teams="sortedTeamsA" @show-history="showHistory" @show-players="showPlayers" />
    <GamesList title="Jogos da Série B" :matches="matchesB" @show-history="showHistory" @show-players="showPlayers" />
    <StandingsTable title="Tabela de Classificação - Série B" :teams="sortedTeamsB" @show-history="showHistory" @show-players="showPlayers" />

    <TeamHistoryModal :team="selectedTeam" :visible="isModalVisible" @close="isModalVisible = false" />
    <AnnualChampions :annualChampions="annualChampions" @show-history="showHistory" @show-players="showPlayers" />
  </div>
</template>

<script>
import { championships, resetTeams, resetCurrentTeams, clearTeamHistory } from '../data/championships';
import { teams } from '../data/teams';
import { simulateRound, getChampion, promoteAndRelegate, getTeamById, incrementTeamStrength, decayTeamStrength, agePlayers } from '../utils/simulator';
import TeamHistoryModal from './TeamHistoryModal';
import GamesList from './GamesList';
import StandingsTable from './StandingsTable';
import AnnualChampions from './AnnualChampions';

const novoTeams = JSON.parse(JSON.stringify(teams));

export default {
  components: {
    TeamHistoryModal,
    GamesList,
    StandingsTable,
    AnnualChampions
  },
  data() {
    return {
      currentChampionshipA: championships.find(champ => champ.type === 'A') || { year: 1990, type: 'A', teams: teams.filter(team => team.series === 'A').map(team => team.id) },
      currentChampionshipB: championships.find(champ => champ.type === 'B') || { year: 1990, type: 'B', teams: teams.filter(team => team.series === 'B').map(team => team.id) },
      currentRound: 0,
      totalRoundsA: 7,
      totalRoundsB: 5,
      matchesA: [],
      matchesB: [],
      isModalVisible: false,
      selectedTeam: null,
      championshipStarted: false,
      championshipFirst: true,
      championshipEnded: false,
      annualChampions: [],
      previousStats: {}
    };
  },
  computed: {
    sortedTeamsA() {
      return this.currentChampionshipA.teams
        .map(id => getTeamById(id, novoTeams))
        .filter(team => team)
        .sort((a, b) => b.points - a.points || b.goalDifference - a.goalDifference);
    },
    sortedTeamsB() {
      return this.currentChampionshipB.teams
        .map(id => getTeamById(id, novoTeams))
        .filter(team => team)
        .sort((a, b) => b.points - a.points || b.goalDifference - a.goalDifference);
    },
    teams() {
      return teams;
    },
    allChampionships() {
      return championships;
    },
  },
  methods: {
    startChampionship() {
      this.resetCurrentTeams();
      this.currentRound = 0;
      this.matchesA = [];
      this.matchesB = [];
      const newYear = this.currentChampionshipA.year;
      this.currentChampionshipA = { year: newYear, teams: [...this.currentChampionshipA.teams], champion: null, type: 'A' };
      this.currentChampionshipB = { year: newYear, teams: [...this.currentChampionshipB.teams], champion: null, type: 'B' };
      championships.push(this.currentChampionshipA);
      championships.push(this.currentChampionshipB);
      this.championshipStarted = true;
      this.championshipFirst = false;
      this.championshipEnded = false;
      this.simulateNextRound();
    },
    simulateNextRound() {
      // Armazenar atributos anteriores
      this.storePreviousStats();

      if (this.currentRound < this.totalRoundsA) {
        this.matchesA = simulateRound(this.currentRound, this.currentChampionshipA, novoTeams);
      }
      if (this.currentRound < this.totalRoundsB) {
        this.matchesB = simulateRound(this.currentRound, this.currentChampionshipB, novoTeams);
      }
      this.currentRound++;
      if (this.currentRound >= this.totalRoundsA && this.currentRound >= this.totalRoundsB) {
        const championA = getChampion(this.currentChampionshipA, novoTeams);
        this.currentChampionshipA.champion = championA;
        incrementTeamStrength(championA); // Incrementa a força do campeão da Série A

        this.currentChampionshipB.champion = getChampion(this.currentChampionshipB, novoTeams);
        this.savePositions(this.currentChampionshipA.year);
        this.savePositions(this.currentChampionshipB.year);
        this.updateAnnualChampions();
        this.championshipEnded = true;

        // Decair a força dos outros times
        novoTeams.forEach(team => {
          if (team.id !== championA.id) {
            decayTeamStrength(team);
          }
        });

        // Envelhecer jogadores e atualizar atributos
        novoTeams.forEach(team => {
          agePlayers(team);
        });

        // Mensagens de aposentadoria
        novoTeams.forEach(team => {
          team.players.forEach(player => {
            if (player.retired) {
              alert(`Jogador ${player.name} do time ${team.name} se aposentou.`);
            }
          });
        });
      }
    },
    storePreviousStats() {
      this.previousStats = {};
      novoTeams.forEach(team => {
        team.players.forEach(player => {
          this.previousStats[player.id] = {
            strength: player.strength,
            speed: player.speed,
            shooting: player.shooting,
            attack: player.attack,
            defense: player.defense
          };
        });
      });
    },
    getStatChange(playerId, stat) {
      if (!this.previousStats[playerId]) return '';
      const previousValue = this.previousStats[playerId][stat];
      const currentValue = getTeamById(playerId, novoTeams)[stat];
      if (currentValue > previousValue) return '▲';
      if (currentValue < previousValue) return '▼';
      return '';
    },
    getAnnualChampions() {
      const years = Array.from(new Set(championships.map(c => c.year)));
      return years.map(year => {
        const seriesAChamp = championships.find(c => c.year === year && c.type === 'A' && c.champion)?.champion;
        const seriesBChamp = championships.find(c => c.year === year && c.type === 'B' && c.champion)?.champion;
        return {
          year,
          seriesA: seriesAChamp,
          seriesB: seriesBChamp
        };
      });
    },
    updateAnnualChampions() {
      this.annualChampions = this.getAnnualChampions();
    },
    startNewChampionship() {
      promoteAndRelegate(this.currentChampionshipA, this.currentChampionshipB, novoTeams);
      resetTeams(this.currentChampionshipA.year, this.currentChampionshipA, novoTeams);
      resetTeams(this.currentChampionshipB.year, this.currentChampionshipB, novoTeams);
      const newYear = this.currentChampionshipA.year + 1;
      this.currentChampionshipA = { year: newYear, teams: [...this.currentChampionshipA.teams], champion: null, type: 'A' };
      this.currentChampionshipB = { year: newYear, teams: [...this.currentChampionshipB.teams], champion: null, type: 'B' };
      championships.push(this.currentChampionshipA);
      championships.push(this.currentChampionshipB);
      this.currentRound = 0;
      this.matchesA = [];
      this.matchesB = [];
      this.championshipStarted = false;
      this.championshipEnded = false;
      this.resetUI();
      this.updateAnnualChampions();
    },
    resetCurrentTeams() {
      resetCurrentTeams(novoTeams);
      this.resetUI();
    },
    clearTeamHistory() {
      clearTeamHistory(novoTeams);
      this.resetUI();
    },
    resetUI() {
      this.currentRound = 0;
      this.matchesA = [];
      this.matchesB = [];
    },
    showHistory(team) {
      this.selectedTeam = team;
      this.isModalVisible = true;
    },
    showPlayers(team) {
      this.selectedTeam = team;
      this.isModalVisible = true;
    },
    savePositions(year) {
      const sortedTeamsA = this.sortedTeamsA;
      const sortedTeamsB = this.sortedTeamsB;

      sortedTeamsA.forEach((team, index) => {
        const historyRecord = team.history.find(record => record.year === year && record.series === 'A');
        if (historyRecord) {
          historyRecord.position = index + 1;
        } else {
          team.history.push({
            year,
            series: 'A',
            position: index + 1,
            points: team.points,
            matches: team.matches,
            wins: team.wins,
            draws: team.draws,
            losses: team.losses,
            goalsFor: team.goalsFor,
            goalsAgainst: team.goalsAgainst,
            goalDifference: team.goalDifference
          });
        }
      });

      sortedTeamsB.forEach((team, index) => {
        const historyRecord = team.history.find(record => record.year === year && record.series === 'B');
        if (historyRecord) {
          historyRecord.position = index + 1;
        } else {
          team.history.push({
            year,
            series: 'B',
            position: index + 1,
            points: team.points,
            matches: team.matches,
            wins: team.wins,
            draws: team.draws,
            losses: team.losses,
            goalsFor: team.goalsFor,
            goalsAgainst: team.goalsAgainst,
            goalDifference: team.goalDifference
          });
        }
      });
    }
  },
  watch: {
    championshipEnded(val) {
      if (val) {
        this.updateAnnualChampions();
      }
    }
  },
  mounted() {
    this.updateAnnualChampions();
  }
};
</script>

<style lang="scss" scoped>
h2 {
  color: #42b983;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.btn {
  &-primary {
    background-color: #007bff;
    color: white;
  }

  &-secondary {
    background-color: #6c757d;
    color: white;
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }
}

.team-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  vertical-align: middle;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  text-align: left;
}

ul {
  list-style-type: none;
  padding: 0;
}

@media (max-width: 600px) {
  h2 {
    margin: 10px;
  }

  h3 {
    margin-top: 5px;
    margin-bottom: 2px;
  }

  table, th, td {
    font-size: 0.8em;
  }

  .buttons {
    flex-direction: column;
  }

  button {
    width: 100%;
  }

  .buttons {
    margin-bottom: 0px;
}

  .team-logo {
    width: 20px;
    height: 20px;
  }
}
</style>
