import { v4 as uuidv4 } from 'uuid';
import { getRandomElement } from '../utils'; // Supondo que você tenha essa função utilitária

export function simulateRound(round, championship, teams) {
  const matches = [];
  const teamIds = championship.teams;
  const totalTeams = teamIds.length;

  // Gerar emparelhamento round-robin sem repetição de jogos
  if (round < totalTeams - 1) {
    for (let i = 0; i < totalTeams / 2; i++) {
      const homeTeamIndex = (round + i) % (totalTeams - 1);
      let awayTeamIndex = (totalTeams - 1 - i + round) % (totalTeams - 1);

      if (i === 0) {
        awayTeamIndex = totalTeams - 1;
      }

      const homeTeam = getTeamById(teamIds[homeTeamIndex], teams);
      const awayTeam = getTeamById(teamIds[awayTeamIndex], teams);

      if (homeTeam && awayTeam) {
        matches.push({
          homeTeam,
          awayTeam,
          round,
          homeGoals: null,
          awayGoals: null
        });
      }
    }
  }

  // Simular resultados dos jogos e atualizar as estatísticas dos times
  matches.forEach(match => {
    const homeGoals = simulateGoals(match.homeTeam);
    const awayGoals = simulateGoals(match.awayTeam);
    match.homeGoals = homeGoals;
    match.awayGoals = awayGoals;
    updateTeamStats(match.homeTeam, homeGoals, awayGoals);
    updateTeamStats(match.awayTeam, awayGoals, homeGoals);
  });

  return matches;
}

export function getTeamById(id, teams) {
  return teams.find(team => team.id === id);
}

function updateTeamStats(team, goalsFor, goalsAgainst) {
  team.goalsFor += goalsFor;
  team.goalsAgainst += goalsAgainst;
  team.goalDifference = team.goalsFor - team.goalsAgainst;
  if (goalsFor > goalsAgainst) {
    team.points += 3;
    team.wins += 1;
  } else if (goalsFor < goalsAgainst) {
    team.losses += 1;
  } else {
    team.points += 1;
    team.draws += 1;
  }
}

function simulateGoals(team) {
  const baseGoals = Math.floor(Math.random() * 3); // 0 a 2 gols base
  const additionalGoals = Math.floor(Math.random() * (team.strength / 2)); // 0 a strength/2 gols adicionais
  const extraGoals = Math.random() < 0.1 ? Math.floor(Math.random() * 4) : 0; // 0 a 3 gols adicionais com 10% de chance
  return baseGoals + additionalGoals + extraGoals;
}

export function getChampion(championship, teams) {
  const sortedTeams = championship.teams
    .map(id => getTeamById(id, teams))
    .sort((a, b) => b.points - a.points || b.goalDifference - a.goalDifference);
  return sortedTeams[0];
}

export function promoteAndRelegate(champA, champB, teams) {
  const sortedTeamsA = champA.teams
    .map(id => getTeamById(id, teams))
    .sort((a, b) => b.points - a.points || b.goalDifference - a.goalDifference);
  const sortedTeamsB = champB.teams
    .map(id => getTeamById(id, teams))
    .sort((a, b) => b.points - a.points || b.goalDifference - a.goalDifference);

  // Promover e rebaixar times
  const teamsToPromote = sortedTeamsB.slice(0, 2);  // Campeão e segundo lugar da Série B
  const teamsToRelegate = sortedTeamsA.slice(-2);   // Último e penúltimo lugar da Série A

  // Atualizar as séries dos times
  champA.teams = champA.teams.filter(id => !teamsToRelegate.some(team => team.id === id));
  champB.teams = champB.teams.filter(id => !teamsToPromote.some(team => team.id === id));

  champA.teams.push(...teamsToPromote.map(team => team.id));
  champB.teams.push(...teamsToRelegate.map(team => team.id));
}

export function incrementTeamStrength(team) {
  team.strength = Math.min(team.strength + 0.5, 10); // Incrementa a força do time até um máximo de 10
}

export function decayTeamStrength(team) {
  team.strength = Math.max(team.strength - 0.1, 1); // Decresce a força do time até um mínimo de 1
}

export function agePlayers(team) {
  team.players.forEach(player => {
    player.age += 1;
    if (player.age >= 35) {
      player.retired = true;
    }
    adjustPlayerAttributes(player);
  });
  team.players = team.players.filter(player => !player.retired);
  if (team.players.length < 23) {
    while (team.players.length < 23) {
      team.players.push(generateNewPlayer(team.id));
    }
  }
}

function adjustPlayerAttributes(player) {
  const ageFactor = player.age >= 30 ? -0.1 : 0.1;
  player.strength = parseFloat((Math.max(1, player.strength + ageFactor)).toFixed(1));
  player.speed = parseFloat((Math.max(1, player.speed + ageFactor)).toFixed(1));
  player.shooting = parseFloat((Math.max(1, player.shooting + ageFactor)).toFixed(1));
  player.attack = parseFloat((Math.max(1, player.attack + ageFactor)).toFixed(1));
  player.defense = parseFloat((Math.max(1, player.defense + ageFactor)).toFixed(1));
  player.value = Math.round((player.strength + player.speed + player.shooting + player.attack + player.defense) * 10000);
}

function generateNewPlayer(teamId) {
  const names = [
    'John', 'Paul', 'George', 'Ringo', 'Lionel', 'Diego', 'Cristiano', 'Zinedine', 'Andrea', 'Francesco',
    'Roberto', 'Carlos', 'Luis', 'Miguel', 'Sergio', 'Rafael', 'Lucas', 'Gabriel', 'Mateo', 'Matias',
    'Pedro', 'João', 'José', 'Antônio', 'Francisco', 'Raimundo', 'Sebastião', 'Jorge', 'Gustavo', 'Ricardo',
    'Tiago', 'Bruno', 'Rodrigo', 'Daniel', 'Felipe', 'Marcos', 'Leonardo', 'Vinícius', 'Eduardo', 'Fernando',
    'Guilherme', 'Samuel', 'Henrique', 'Vitor', 'Marcelo', 'Igor', 'Caio', 'André', 'Alexandre', 'Hugo',
    'Renato', 'Márcio', 'Alex', 'Fábio', 'Paulo', 'Adriano', 'Rogério', 'Murilo', 'Joaquim', 'Cristiano',
    'Edson', 'Leandro', 'Luciano', 'Maurício', 'Otávio', 'Raul', 'Sandro', 'Valter', 'Yuri', 'Zeca'
  ];

  const surnames = [
    'Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia', 'Martinez', 'Davis', 'Lopez', 'Gonzalez',
    'Silva', 'Santos', 'Oliveira', 'Souza', 'Lima', 'Pereira', 'Ferreira', 'Almeida', 'Costa', 'Rodrigues',
    'Martins', 'Rocha', 'Ribeiro', 'Alves', 'Barbosa', 'Cardoso', 'Correia', 'Farias', 'Mendes', 'Nogueira',
    'Teixeira', 'Vieira', 'Monteiro', 'Carvalho', 'Azevedo', 'Fonseca', 'Moreira', 'Barros', 'Campos', 'Moraes'
  ];

  const positions = ['G', 'D', 'M', 'A']; // Goleiro, Defensor, Meio-campista, Atacante

  const nationalities = [
    'BRA', 'ARG', 'ALE', 'FRA', 'ITA', 'ESP', 'POR', 'ING', 'HOL', 'BEL',
    'URU', 'COL', 'CHI', 'PER', 'PAR', 'MEX', 'USA', 'CAN', 'AUS', 'JAP'
  ];

  const firstName = getRandomElement(names);
  const lastName = getRandomElement(surnames);
  const position = getRandomElement(positions);
  const nationality = getRandomElement(nationalities);
  const age = Math.floor(Math.random() * 10) + 18; // Idade entre 18 e 27
  const strength = parseFloat((Math.random() * 100 + 1).toFixed(1)); // Força entre 1 e 100
  const speed = parseFloat((Math.random() * 100 + 1).toFixed(1)); // Velocidade entre 1 e 100
  const shooting = parseFloat((Math.random() * 100 + 1).toFixed(1)); // Chute entre 1 e 100
  const attack = parseFloat((Math.random() * 100 + 1).toFixed(1)); // Ataque entre 1 e 100
  const defense = parseFloat((Math.random() * 100 + 1).toFixed(1)); // Defesa entre 1 e 100
  const value = Math.round((strength + speed + shooting + attack + defense) * 10000); // Valor baseado nos atributos

  return {
    id: uuidv4(),
    teamId,
    name: `${firstName} ${lastName}`,
    position,
    nationality,
    age,
    strength,
    speed,
    shooting,
    attack,
    defense,
    value,
    retired: false,
    starPlayer: Math.random() < 0.1 // 10% de chance de ser um jogador estrela
  };
}
