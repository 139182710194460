<!-- src/App.vue -->
<template>
  <div id="app">
    <MatchSimulator />
  </div>
</template>

<script>
import MatchSimulator from './components/MatchSimulator';

export default {
  components: {
    MatchSimulator,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
@media (max-width: 600px) {
  #app {
  margin-top: 5px;

  }
}
</style>
