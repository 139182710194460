<template>
    <div class="modal-content">
      <span class="close" @click="close">&times;</span>
      <h3>
        <img :src="team.img" alt="Team logo" class="team-logo">
        {{ team.name }}
      </h3>
      <div class="summary">
        <p>Série A: {{ seriesAChampionships }}x campeão</p>
        <p>Série B: {{ seriesBChampionships }}x campeão</p>
      </div>
      <div class="tabs">
        <button @click="currentTab = 'history'" :class="{ active: currentTab === 'history' }">Histórico</button>
        <button @click="currentTab = 'players'" :class="{ active: currentTab === 'players' }">Jogadores</button>
      </div>
      <div v-if="currentTab === 'history'">
        <h4>Histórico</h4>
        <table>
          <thead>
            <tr>
              <th>Ano</th>
              <th>Série</th>
              <th>Pos</th>
              <th>Pts</th>
              <th>J</th>
              <th>V</th>
              <th>E</th>
              <th>D</th>
              <th>GP</th>
              <th>GC</th>
              <th>SG</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="record in uniqueHistory" :key="record.year + '-' + record.series" :class="getRowClass(record)">
              <td>{{ record.year }}</td>
              <td>{{ record.series }}</td>
              <td>{{ record.position }}</td>
              <td>{{ record.points }}</td>
              <td>{{ record.wins + record.draws + record.losses }}</td>
              <td>{{ record.wins }}</td>
              <td>{{ record.draws }}</td>
              <td>{{ record.losses }}</td>
              <td>{{ record.goalsFor }}</td>
              <td>{{ record.goalsAgainst }}</td>
              <td>{{ record.goalDifference }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="currentTab === 'players'">
        <h4>Jogadores</h4>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Pos</th>
              <th>Id</th>
              <th>Nac</th>
              <th>For</th>
              <th>Vel</th>
              <th>Chu</th>
              <th>Ata</th>
              <th>Def</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="player in team.players" :key="player.id">
              <td>{{ player.name }}<span v-if="player.starPlayer">⭐</span></td>
              <td>{{ player.position }}</td>
              <td>{{ player.age }}</td>
              <td>{{ player.nationality.slice(0, 3) }}</td>
              <td>{{ player.strength }}</td>
              <td>{{ player.speed }}</td>
              <td>{{ player.shooting }}</td>
              <td>{{ player.attack }}</td>
              <td>{{ player.defense }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ['team'],
    data() {
      return {
        currentTab: 'history'
      };
    },
    computed: {
      uniqueHistory() {
        const historyMap = new Map();
        this.team.history.forEach(record => {
          const key = `${record.year}-${record.series}`;
          if (!historyMap.has(key) && record.points !== undefined) {
            historyMap.set(key, record);
          }
        });
        return Array.from(historyMap.values());
      },
      seriesAChampionships() {
        return this.team.history.filter(record => record.series === 'A' && record.position === 1).length;
      },
      seriesBChampionships() {
        return this.team.history.filter(record => record.series === 'B' && record.position === 1).length;
      }
    },
    methods: {
      close() {
        this.$emit('close');
      },
      getRowClass(record) {
      if (record.series === 'B') {
        return 'serie-b';
      } else if (record.series === 'A' && record.position === 1) {
        return 'serie-a-winner';
      }
      return '';
    }
    }
  };
  </script>
  
  <style scoped>
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 90%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .summary {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  button {
    padding: 10px;
    border: none;
    cursor: pointer;
  }
  
  button.active {
    font-weight: bold;
    color: #42b983;
  }
  
  .team-logo {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
  }

  .serie-b {
  background-color: #f9e5e7;
}

.serie-a-winner {
  background-color: #fafbaeab;
}
  
  @media (max-width: 600px) {
    .modal-content {
      width: 90%;
      padding: 10px;
    }
  
    .team-logo {
      width: 20px;
      height: 20px;
    }
  
    table, th, td {
      font-size: 0.8em;
    }
  }
  </style>
  