// src/main.js
import Vue from 'vue';
import App from './App.vue';
import { loadData, saveData } from './utils/storage';
import { teams } from './data/teams';

const savedTeams = loadData('teams');
if (savedTeams) {
  teams.splice(0, teams.length, ...savedTeams);
}

new Vue({
  render: h => h(App),
}).$mount('#app');

window.addEventListener('beforeunload', () => {
  saveData('teams', teams);
});
