<template>
    <div class="games-list">
      <h3>{{ title }}</h3>
      <div v-for="(matches, round) in groupedMatches" :key="round" class="round">
        <h4>Rodada: {{ parseInt(round) + 1 }}</h4>
        <ul>
          <li v-for="match in matches" :key="match.homeTeam.id + '-' + match.awayTeam.id" class="match">
            <div class="team home">
              <img :src="match.homeTeam.img" alt="Home team logo" class="team-logo">
              <span :data-full="match.homeTeam.name" :data-short="match.homeTeam.abbreviation" @click="$emit('show-history', match.homeTeam)">{{ match.homeTeam.name }}</span>
            </div>
            <div class="score">{{ match.homeGoals }} - {{ match.awayGoals }}</div>
            <div class="team away">
              <span :data-full="match.awayTeam.name" :data-short="match.awayTeam.abbreviation" @click="$emit('show-history', match.awayTeam)">{{ match.awayTeam.name }}</span>
              <img :src="match.awayTeam.img" alt="Away team logo" class="team-logo m-l">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
      matches: Array
    },
    computed: {
      groupedMatches() {
        return this.matches.reduce((acc, match) => {
          if (!acc[match.round]) {
            acc[match.round] = [];
          }
          acc[match.round].push(match);
          return acc;
        }, {});
      }
    },
    mounted() {
      this.updateTeamNames();
      window.addEventListener('resize', this.updateTeamNames);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateTeamNames);
    },
    methods: {
      updateTeamNames() {
        const isMobile = window.innerWidth <= 600;
        const teamNames = document.querySelectorAll('span[data-full][data-short]');
        teamNames.forEach(name => {
          if (isMobile) {
            name.textContent = name.getAttribute('data-short');
          } else {
            name.textContent = name.getAttribute('data-full');
          }
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .games-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 0 auto;
  }
  
  h3 {
    color: #42b983;
    margin-bottom: 10px;
  }
  
  h4 {
    margin-top: 20px;
    color: #2c3e50;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
  }
  
  .match {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #f9f9f9;
    flex-wrap: wrap;
  }
  
  .match .team {
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 1em;
  }
  
  .team-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .match .score {
    flex: 0 0 60px;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    background-color: #42b983;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
  }
  
  .away {
    display: flex;
    justify-content: flex-end;
  }
  
  .m-l {
    margin-left: 5px;
  }

  .round {
      width: 70%;
    }
  
  @media (max-width: 600px) {
    .games-list {
      width: 100%;
    }

    h4 {
        margin: 0;
    }
  
    .round {
      width: 100%;
    }
  
    .match {
      padding: 5px;
    }
  
    .match .team {
      font-size: 0.9em;
    }
  
    .match .score {
      flex: 0 0 50px;
      font-size: 1em;
    }
  
    .team-logo {
      width: 20px;
      height: 20px;
    }
  }
  </style>
  