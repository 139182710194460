<template>
  <div v-if="visible" class="modal">
    <component :is="currentComponent" :team="team" @close="close" />
  </div>
</template>

<script>
import TeamHistoryModalDesktop from './TeamHistoryModalDesktop.vue';
import TeamHistoryModalMobile from './TeamHistoryModalMobile.vue';

export default {
  components: {
    TeamHistoryModalDesktop,
    TeamHistoryModalMobile
  },
  props: ['team', 'visible'],
  data() {
    return {
      isMobile: window.innerWidth <= 600
    };
  },
  computed: {
    currentComponent() {
      return this.isMobile ? 'TeamHistoryModalMobile' : 'TeamHistoryModalDesktop';
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 600;
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.checkIsMobile();
        window.addEventListener('resize', this.checkIsMobile);
      } else {
        window.removeEventListener('resize', this.checkIsMobile);
      }
    }
  }
};
</script>

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
