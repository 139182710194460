export const championships = [
  { year: 1990, teams: [1, 2, 3, 4, 12, 6, 7, 8], champion: null, type: 'A' },
  { year: 1990, teams: [9, 10, 11, 5, 13, 14], champion: null, type: 'B' }
];

export function resetTeams(year, championship, teamsList) {
  championship.teams.forEach(teamId => {
    const team = getTeamById(teamId, teamsList);
    if (team) {
      team.points = 0;
      team.matches = 0;
      team.wins = 0;
      team.draws = 0;
      team.losses = 0;
      team.goalsFor = 0;
      team.goalsAgainst = 0;
      team.goalDifference = 0;
      team.history = team.history || [];
      team.history.push({ year, position: null });
    }
  });
}

export function resetCurrentTeams(teamsList) {
  teamsList.forEach(team => {
    team.points = 0;
    team.matches = 0;
    team.wins = 0;
    team.draws = 0;
    team.losses = 0;
    team.goalsFor = 0;
    team.goalsAgainst = 0;
    team.goalDifference = 0;
  });
}

export function clearTeamHistory(teamsList) {
  teamsList.forEach(team => {
    team.history = [];
  });
}

export function getTeamById(id, teamsList) {
  const team = teamsList.find(team => team.id === id);
  if (!team) {
    console.error(`Team with ID ${id} not found`);
  }
  return team;
}

export function getChampion(championship, teamsList) {
  const sortedTeams = championship.teams
    .map(id => getTeamById(id, teamsList))
    .sort((a, b) => b.points - a.points || b.goalDifference - a.goalDifference);
  return sortedTeams[0];
}

export function promoteAndRelegate(serieA, serieB, teamsList) {
  const sortedA = serieA.teams
    .map(id => getTeamById(id, teamsList))
    .sort((a, b) => b.points - a.points || b.goalDifference - a.goalDifference);
  const sortedB = serieB.teams
    .map(id => getTeamById(id, teamsList))
    .sort((a, b) => b.points - a.points || b.goalDifference - a.goalDifference);

  const relegated = sortedA.slice(-2).map(team => team.id);
  const promoted = sortedB.slice(0, 2).map(team => team.id);

  serieA.teams = serieA.teams.filter(id => !relegated.includes(id)).concat(promoted);
  serieB.teams = serieB.teams.filter(id => !promoted.includes(id)).concat(relegated);
}
