<template>
    <div>
      <h3>Campeões Anuais</h3>
      <table>
        <thead>
          <tr>
            <th>Ano</th>
            <th>Série A</th>
            <th>Série B</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="championship in annualChampions" :key="championship.year">
            <td>{{ championship.year }}</td>
            <td class="left">
              <img v-if="championship.seriesA" :src="championship.seriesA.img" alt="Series A champion logo" class="team-logo">
              <span v-if="championship.seriesA" @click="$emit('show-history', championship.seriesA)">
                {{ championship.seriesA.name }}
              </span>
              <span v-else>Ainda não definido</span>
            </td>
            <td class="left">
              <img v-if="championship.seriesB" :src="championship.seriesB.img" alt="Series B champion logo" class="team-logo">
              <span v-if="championship.seriesB" @click="$emit('show-history', championship.seriesB)">
                {{ championship.seriesB.name }}
              </span>
              <span v-else>Ainda não definido</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      annualChampions: {
        type: Array,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  .team-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    vertical-align: middle;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
    text-align: left;
  }

  .left {
    text-align: left;
  }
  
  @media (max-width: 600px) {
    table, th, td {
      font-size: 0.8em;
    }
  
    .team-logo {
      width: 20px;
      height: 20px;
    }
  }
  </style>
  