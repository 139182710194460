// src/data/teams.js
import { generatePlayers } from '../utils/playerGenerator';

export const teams = [
  { id: 1, name: 'Operário-MS', abbreviation: 'OP-MS', img: '../assets/times/Operario-MS.svg', points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, matches: 0, wins: 0, draws: 0, losses: 0, history: [], strength: 1, players: generatePlayers(1) },
  { id: 2, name: 'Coxim', abbreviation: 'COX', img: '../assets/times/coxim65.png', points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, matches: 0, wins: 0, draws: 0, losses: 0, history: [], strength: 1, players: generatePlayers(2) },
  { id: 3, name: 'Portuguesa-MS', abbreviation: 'POR-MS', img: '../assets/times/escudo-portuguesa-ms-svg-66862.svg', points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, matches: 0, wins: 0, draws: 0, losses: 0, history: [], strength: 1, players: generatePlayers(3) },
  { id: 4, name: 'Costa Rica-MS', abbreviation: 'CR-MS', img: '../assets/times/costaricaec-ms65.png', points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, matches: 0, wins: 0, draws: 0, losses: 0, history: [], strength: 1, players: generatePlayers(4) },
  { id: 5, name: 'Náutico-MS', abbreviation: 'NAU-MS', img: '../assets/times/Náutico-65x65.png', points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, matches: 0, wins: 0, draws: 0, losses: 0, history: [], strength: 1, players: generatePlayers(5) },
  { id: 6, name: 'Dourados', abbreviation: 'DOUR', img: '../assets/times/escudo-pequeno-38538.png', points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, matches: 0, wins: 0, draws: 0, losses: 0, history: [], strength: 1, players: generatePlayers(6) },
  { id: 7, name: 'Ivinhema', abbreviation: 'IVI', img:'../assets/times/ivinhema-65.png', points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, matches: 0, wins: 0, draws: 0, losses: 0, history: [], strength: 1, players: generatePlayers(7) },
  { id: 8, name: 'Aquidauanense', abbreviation: 'AQD', img: '../assets/times/Aquidauanense-65.png', points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, matches: 0, wins: 0, draws: 0, losses: 0, history: [], strength: 1, players: generatePlayers(8) },
  { id: 9, name: 'Corumbaense', abbreviation: 'COR', img:'../assets/times/Corumbaense-65.png', points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, matches: 0, wins: 0, draws: 0, losses: 0, history: [], strength: 1, players: generatePlayers(9) },
  { id: 10, name: 'Novo', abbreviation: 'NOVO', img: '../assets/times/escudo-novoperario-65x65.png', points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, matches: 0, wins: 0, draws: 0, losses: 0, history: [], strength: 1, players: generatePlayers(10) },
  { id: 11, name: 'União Abc', abbreviation: 'UABC', img: '../assets/times/240px-UniãoABC.png', points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, matches: 0, wins: 0, draws: 0, losses: 0, history: [], strength: 1, players: generatePlayers(11) },
  { id: 12, name: 'Águia Negra', abbreviation: 'AGU', img: '../assets/times/240px-ECAguiaNegra.png', points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, matches: 0, wins: 0, draws: 0, losses: 0, history: [], strength: 1, players: generatePlayers(12) },
  { id: 13, name: 'Pantanal', abbreviation: 'PANT', img: '../assets/times/PantanalFC.png', points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, matches: 0, wins: 0, draws: 0, losses: 0, history: [], strength: 1, players: generatePlayers(13) },
  { id: 14, name: 'Comercial-MS', abbreviation: 'COM-MS', img: '../assets/times/EC_Comercial_ms.png', points: 0, goalsFor: 0, goalsAgainst: 0, goalDifference: 0, matches: 0, wins: 0, draws: 0, losses: 0, history: [], strength: 1, players: generatePlayers(14) }
];

